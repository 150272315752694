import Glide from '@glidejs/glide'

document.addEventListener('DOMContentLoaded', () => {

  const reviewsSlider = document.querySelector('.glide')

  if (!reviewsSlider) {
    return;
  }

  new Glide(reviewsSlider, {
    type: 'carousel',
    startAt: 0,
    perView: 2,
    autoplay: 4000
  }).mount()

});
