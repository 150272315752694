import(/* webpackChunkName: "fanalytics" */ '@fiizy/fanalytics')

import '../css/critical.scss'
import '../css/main.scss'

import './auth';
import './navigation';
import './products';
import './reviews';
import './tabs';

import hideLoading from '../../../common/scripts/hideLoading'
import cookieLaw from '../../../common/scripts/cookieLaw'
import { lazyLoadImages } from '../../../common/scripts/lazyLoad'

import {applyPolyfills, defineCustomElements} from '@fiizy/stylebook/loader'

applyPolyfills().then(() => {
  defineCustomElements()
})

window.loadProduct = function loadProduct(loanType) {
  const urlSearchParams = new URLSearchParams(window.location.search)
  urlSearchParams.set('loanType', loanType)
  window.location.href = 'process?' + urlSearchParams.toString()
}

document.addEventListener('DOMContentLoaded', () => {
  hideLoading()
  lazyLoadImages()
  cookieLaw()
})
