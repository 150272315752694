import { agreeCookies } from '../../../common/scripts/cookieLaw'

function buildProcessUrl(baseUrl, params) {
  const url = new URL(baseUrl)
  const searchParams = new URLSearchParams(window.location.search)
  const urlParams = new URLSearchParams({
    ...Object.fromEntries(url.searchParams),
    ...Object.fromEntries(searchParams),
    ...Object.fromEntries(params)
  });
  return `${url.origin}${url.pathname}?${urlParams}`
}

function setupProductCalculator(container) {
  const processUrl = container.dataset.url;
  const productType = container.dataset.product;
  let applicationPrincipal = container.dataset.applicationPrincipal;
  let applicationPeriod = container.dataset.applicationPeriod;
  const submitButton = container.querySelector('.product-button')
  const submitLink = container.querySelector('#submitLink')
  const amountSlider = container.querySelector('.amount-slider');
  const periodSlider = container.querySelector('.period-slider');
  const isLink = submitButton.classList.contains('product-button-link');
  const isForm = container.classList.contains('container-form')

  if (!submitButton) {
    return;
  }

  let formFields = new Map()
  let formFieldValues = new Map()
  let formFieldIDs = [
    'firstName',
    'lastName',
    'email',
    'phoneMobile',
    'confirmTerms',
    'confirmCreditorTerms',
    'confirmDataProcessing',
    'confirmExternalMarketing',
    'confirmWhatsapp',
  ]

  if (isForm) {
    for (let i = 0; i < formFieldIDs.length; i++) {
      if (container.querySelector('#' + formFieldIDs[i])) {
        formFields.set(formFieldIDs[i], container.querySelector('#' + formFieldIDs[i]));
      }
    }

    addFieldListeners(formFields, submitButton, submitLink, formFieldValues)
    validateForm(formFields, submitButton, submitLink)
  }

  if (isLink) {
    const params = new URLSearchParams();
    params.append('loanType', productType)

    if (periodSlider) {
      params.append('applicationPeriod', applicationPeriod);
    }
    if (amountSlider) {
      params.append('applicationPrincipal', applicationPrincipal);
    }

    submitButton.setAttribute('href', buildProcessUrl(processUrl, params))
  }

  submitButton.addEventListener('click', function() {
    agreeCookies()

    if (isLink) {
      return
    }

    const params = new URLSearchParams();
    params.set('loanType', productType)
    params.set('applicationPrincipal', applicationPrincipal)

    if (isForm) {
      const isValid = validateForm(formFields, submitButton, submitLink)
      if (!isValid) {
        return
      }
      for (const [fieldKey, fieldValue] of formFieldValues) {
        params.set(fieldKey, fieldValue)
      }
    }

    window.location.href = buildProcessUrl(processUrl, params)
  })

  if (amountSlider) {
    amountSlider.addEventListener('fiInput', (e) => {
      applicationPrincipal = e.detail

      if (!isLink) {
        return;
      }

      const params = new URLSearchParams();
      params.append('loanType', productType)
      params.append('applicationPrincipal', applicationPrincipal)

      if (periodSlider) {
        params.append('applicationPeriod', applicationPeriod);
      }

      submitButton.setAttribute('href', buildProcessUrl(processUrl, params))
    })
  }

  if (periodSlider) {
    periodSlider.addEventListener('fiInput', (e) => {
      applicationPeriod = e.detail

      if (!isLink) {
        return;
      }

      const params = new URLSearchParams();
      params.append('loanType', productType)
      params.append('applicationPeriod', applicationPeriod);

      if (amountSlider) {
        params.append('applicationPrincipal', applicationPrincipal);
      }

      submitButton.setAttribute('href', buildProcessUrl(processUrl, params))
    })
  }
}

function addFieldListeners(fields, submitButton, submitLink, formFieldValues) {
  for (const [fieldKey, field] of fields) {
    field.addEventListener('fiInput', (e) => {
      let value = e.detail
      if (e.detail.unmasked) {
        value = e.detail.unmasked
      }

      const regex = field.getAttribute('validation-regex')
      if (regex) {
        if (validateValue(value, regex)) {
          field.setAttribute('validation-class', 'valid')
          formFieldValues.set(fieldKey, value)
        } else {
          field.setAttribute('validation-class', 'error')
        }
      } else {
        formFieldValues.set(fieldKey, value)
      }

      if (submitLink) {
        const searchParams = new URLSearchParams(submitLink.search)
        for (const [fieldKey, fieldValue] of formFieldValues) {
          searchParams.set(fieldKey, fieldValue)
        }
        submitLink.search = searchParams
      }

      validateForm(fields, submitButton, submitLink)
    })
  }
}

function validateForm(fields, submitButton, submitLink) {
  let validFields = 0

  for (const field of fields.values()) {
    const isValid = field.getAttribute('validation-class')
    const regex = field.getAttribute('validation-regex')


    if (regex === null && isValid === null) {
      validFields++
    }

    if (isValid === 'valid') {
      validFields++
    }
  }

  if (validFields === fields.size) {
    submitButton.setAttribute('disabled', 'false')

    if (submitLink) {
      submitLink.classList.remove('disabled-link')
    }

    return true
  } else {
    submitButton.setAttribute('disabled', 'true')

    if (submitLink) {
      submitLink.classList.add('disabled-link')
    }
  }

  return false
}

function validateValue(value, regex) {
  return new RegExp(regex).test(value)
}

document.addEventListener('DOMContentLoaded', () => {
  const calculators = document.getElementsByClassName('calculator')

  if (calculators.length == 0) {
    return
  }

  for (let e of calculators) {
    setupProductCalculator(e)
  }
})
