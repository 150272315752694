function activateTab(tabs, targetId) {
  const target = document.getElementById(targetId);

  if (!target) {
    console.error("failed to find target", el, targetId);
    return;
  }

  // un-select current selected tab
  tabs.querySelector('.tab-link[aria-selected="true"]').setAttribute('aria-selected', 'false');
  // set target as selected
  tabs.querySelector(`[aria-controls="${targetId}"]`).setAttribute('aria-selected', 'true');
  // hide all tab content
  target.parentNode.querySelectorAll('[role="tabpanel"]').forEach((p) => p.setAttribute("hidden", 'true'));
  // show target
  target.removeAttribute('hidden');
  // update bg
  tabs.querySelectorAll('.bg').forEach((e) => e.getAttribute('data-for') === targetId ? e.classList.add('active') : e.classList.remove('active'));
}

function changeTabs(e) {
	let el = e.target;

	if (el.getAttribute('role') == 'presentation') {
		el = el.parentNode;
	}

	const targetId = el.getAttribute("aria-controls");
	const tabs = el.closest('.tabs');

	activateTab(tabs, targetId)
}

document.addEventListener('DOMContentLoaded', () => {

	const tabs = Array.prototype.slice.call(document.querySelectorAll('[role="tab"]'), 0);

	// Add a click event handler to each tab
	tabs.forEach( el => {
		el.addEventListener('click', changeTabs);
	});

	// Enable arrow navigation between tabs in the tab list
	const tabLists = Array.prototype.slice.call(document.querySelectorAll('[role="tablist"]'), 0)

	tabLists.forEach( el => {
		let tabFocus = 0;
		el.addEventListener("keydown", (e) => {
			// Move right
			if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
				tabs[tabFocus].setAttribute("tabindex", -1);
				if (e.key === "ArrowRight") {
					tabFocus++;
					// If we're at the end, go to the start
					if (tabFocus >= tabs.length) {
						tabFocus = 0;
					}
					// Move left
				} else if (e.key === "ArrowLeft") {
					tabFocus--;
					// If we're at the start, move to the end
					if (tabFocus < 0) {
						tabFocus = tabs.length - 1;
					}
				}

				tabs[tabFocus].setAttribute("tabindex", 0);
				tabs[tabFocus].focus();
			}
		});
	});

});

window.addEventListener('hashchange', () => {
  const urlParam = window.location.hash;
  const target = document.querySelector(urlParam);

  if (!target) {
    return;
  }

  if (target.getAttribute('role') !== 'tabpanel') {
    return;
  }

  const targetId = target.getAttribute('id');

  if (!targetId) {
    return;
  }

  const tabs = target.parentNode.querySelector('[role="tablist"]');

  if (!tabs) {
    return;
  }

  activateTab(tabs, targetId)
});
